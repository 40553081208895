import logo from './logo.svg';
import './App.css';

import LoginPage from './components/loginPage/LoginPage';
import MainPage from './components/MainPage/MainPage';
import { useState } from 'react';

function App() {
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(null);
  if(isLogged){
    return(<MainPage user={user}/>)
  }
  return (
    <LoginPage setIsLogged = {setIsLogged} setUser={setUser}/>
  );
}

export default App;
