import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart } from "react-google-charts";
// import { Tabs } from 'antd-mobile';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { Tabs } from 'antd';
import "./MyTable.css";

import calendarIcon from '../../img/schedule-calendar-icon.svg';
const { RangePicker } = DatePicker;


const PaymentStats = (props) => {
  const [payments, setPayments] = useState([]);
  const [currentPost, setCurrentPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [dateInput, setDateInput] = useState({
    from: '',
    to: ''
  })
  const [dateRange, setDateRange] = useState({
    from: '',
    to: ''
  });
  const [paymentSummary, setPaymentSummary] = useState(null);
  const [paymentIncasations, setPaymentIncasations] = useState([{date: null, data: []}]);
  const [currentPeriod, setCurrentPeriod] = useState("С последней инкассации");
  const [stationsForIncasations, setStationsForIncasations] = useState([]);
  const [toIncasationData, setToIncasationData] = useState({station: null, paymentType: null})
  const [user, setUser] = useState(null)

  useEffect(()=>{console.log(props); if(props.user != null){setUser(props.user)}else{let cookieUser = getCookie('username');
  setUser(cookieUser)}}, [props])


  useEffect(()=>{
    if(user === null){
      return
    }
    console.log('Юзер стейт обновлен')
    getTotalSum();
    getIncasationHistory();
    
  }, [user])

  function convertDate(milliseconds) {
    const date = new Date(parseInt(milliseconds));
    return date.toISOString().split('T')[0]; // Преобразует дату в формат YYYY-MM-DD
}

function getCookie(name) {
  let cookies = document.cookie;
  let parts = cookies.split(name + "=");
  if (parts.length === 2) {
      return parts.pop().split(";").shift();
  }
  return null;
}

const getIncasationHistory = () =>{
  const fetchData1 = async () => {
    try {
      const response = await axios.get("https://mwa.arc.kz/CarFash/GetCarWashCashCollection?bin=" + user);
      setPaymentIncasations(createFormattedArray(response.data)); 
      const uniqueStationIds = [];

      response.data.forEach(item => {
          if (!uniqueStationIds.includes(item.item_station_id)) {
              uniqueStationIds.push(item.item_station_id);
          }
      });
      setStationsForIncasations(uniqueStationIds);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  fetchData1();
}

function groupByDateAndStationId(data) {
    const groupedByDate = {};
    data.forEach(item => {
        const date = new Date(item.actionDt).toLocaleString('ru',{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
        console.log(item.actionDt)
        console.log('Проверка дат:', date)
        if (!groupedByDate[date]) {
            groupedByDate[date] = {};
        }
        if (!groupedByDate[date][item.item_station_id]) {
            groupedByDate[date][item.item_station_id] = [];
        }
        groupedByDate[date][item.item_station_id].push(item);
    });
    return groupedByDate;
}

// function groupByDateAndStationId(data) {
//   const groupedByDate = {};

//   data.forEach(item => {
//       // Check if actionDt is in /Date(...)/ format and extract the timestamp
//       const match = item.actionDt.match(/\/Date\((\d+)\)\//);
//       const timestamp = match ? parseInt(match[1]) : null;
      
//       if (timestamp) {
//           const date = new Date(timestamp).toLocaleString('ru', {
//               day: '2-digit',
//               month: '2-digit',
//               year: 'numeric',
//           });
          
//           console.log('Original Date String:', item.actionDt);
//           console.log('Formatted Date:', date);

//           if (!groupedByDate[date]) {
//               groupedByDate[date] = {};
//           }

//           if (!groupedByDate[date][item.item_station_id]) {
//               groupedByDate[date][item.item_station_id] = [];
//           }

//           groupedByDate[date][item.item_station_id].push(item);
//       } else {
//           console.error('Invalid date format in item:', item);
//       }
//   });

//   return groupedByDate;
// }


function createFormattedArray(data) {
    const groupedData = groupByDateAndStationId(data);
    return Object.keys(groupedData).map(date => ({
        date: date,
        data: Object.keys(groupedData[date]).map(stationId => ({
            itemstation_id: stationId,
            stationData: groupedData[date][stationId]
        }))
    }));
}

function exit(){
  document.cookie = 'username=; path=/; expires=-1';
  window.location.reload();
}

  useEffect(() => {
    getAllPayments()
  }, [dateRange]);

  const getAllPayments = () =>{
    const fetchData = async () => {
      try {
        const response = await axios.get("https://mwa.arc.kz/CarFash/GetPCPaymentCarWash?bin=" + user + "&DtFrom=" + dateRange.from + "T00:00:00&DtTo=" + dateRange.to + "T23:59:59");
        // setPosts(getUniqueStationIds(response.data));
        // processPayments(filterPayments(response.data, currentPost));
        setPaymentSummary(processRecords(response.data));
        console.log(paymentSummary)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
     
    };
    fetchData();
  }


  function processRecords(records) {
    const result = {};

    records.forEach(record => {
        const stationId = record.item_station_id || '';
        const sumAmount = record.sum || 0;
        const typePay = record.typePay || '';

        // Проверяем, есть ли уже такой stationId в результате
        if (!result[stationId]) {
            result[stationId] = { id: stationId, sumCoin: 0, sumCash: 0, sumQR: 0 };
        }

        // Агрегируем суммы в зависимости от типа платежа
        switch (typePay) {
            case 10:
                result[stationId].sumCoin += sumAmount;
                break;
            case 20:
                result[stationId].sumCash += sumAmount;
                break;
            case 30:
                result[stationId].sumQR += sumAmount;
                break;
        }
    });

    return Object.values(result);
}

  const formatDate = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Месяцы начинаются с 0
    var day = date.getDate();

    // Добавляем ведущий ноль, если число меньше 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return year + '-' + month + '-' + day;
  };

  const styles = {
    customHeader: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid var(--adm-color-border)',
      tabs: {
        flex: 1,
        global: {
          // This part needs special handling because ':global' is not a valid key in React inline styles.
        },
      },
      moreIcon: {
        fontSize: 'var(--adm-font-size-9)',
        margin: '0 12px',
        transform: 'translateY(-2px)',
      },
    },
  };

  const showDateSelect = (status) =>{
    // document.getElementsByClassName("daterangeSelect")[0].style.bottom = status ? '0' : "-100%";
    document.getElementsByClassName("daterangeSelect")[1].style.transform = status ? 'translateY(0)' : "translateY(100%)";
    
    
  }

  const showDateCalendar = (status) =>{
    // document.getElementsByClassName("daterangeSelect")[0].style.bottom = status ? '0' : "-100%";
    document.getElementsByClassName("daterangeSelect")[0].style.transform = status ? 'translateY(0)' : "translateY(100%)";
    
    
  }

  const showIncasationMenu = (status) =>{
    document.getElementById('incasationMenu').style.transform = status ? 'translateY(0)' : "translateY(100%)";
  }


  useEffect(() => {
    if (dateInput.from && dateInput.to) {
        // Функция вызывается только тогда, когда оба поля дат заполнены
        getByCalendar();
    }
}, [dateInput]); // Эффект будет вызван при изменении dateInput

  const getByCalendar = () =>{
    console.log(dateInput);
    setDateRange(dateInput);
    setCurrentPeriod(dateInput.from + " - " + dateInput.to)
  }

  const changeTab = (e) => {
    if(e == "666"){
      setCurrentPost(null)
    }else{
      setCurrentPost(e)
    }
  };

  const doIncassation = () =>{
    const fetchData = async () => {
      try {
        const response = await axios.get("https://mwa.arc.kz/CarFash/AddCarWashCashCollection?item_station_id=" + toIncasationData.station + "&typePay=" + toIncasationData.paymentType + "&comment=");
        if(response.data = "OK"){
          alert("Инкассация завершена успешно")
          getIncasationHistory()
          getAllPayments()
          getTotalSum()
        }else{
          alert("Не удалось инкассировать")
        }
        // console.log(response.data) 
      } catch (error) {
        console.error('Error fetching data:', error);
        alert("Ошибка")
      }
     
    };
    fetchData();
  }

  const incasationSection = 
  <div>
     <div className='incasation-wrap'>
        <button className='incasation-button' onClick={showIncasationMenu}>Совершить инкасацию</button>
      </div>
    <h2>Прошлые инкассации</h2>
                              {paymentIncasations.map(el=>{
                                return(
                                  <div className='payments-wrap incasation-wrap1'>
                                      <p className='incassation-date'>{el.date}</p>
                                      {el.data.sort((a,b) => a.itemstation_id.split("0")[a.itemstation_id.split("0").length-1] - b.itemstation_id.split("0")[b.itemstation_id.split("0").length-1]).map(el1=>{
                                        return(
                                          <>
                                            <h2 className='payments-wrap-title'>Бокс {el1.itemstation_id.split("0")[el1.itemstation_id.split("0").length-1]}</h2>
                                            {el1.stationData.map(ss=>
                                            <div className='payments-box'>
                                              <p className='payments-box-type'>{ss.typePayName}: </p>
                                              <p className='payments-box-value'><span>{ss.sum}</span> ₸</p>
                                            </div>)}
                                          </>
                                        )
                                      })}
                                  </div>
                                )
                              })}
  </div>;
 

  const statisticSection = <div>
                                <div>
                                  <div className='dateSettings' onClick={showDateSelect}>
                                    <img className='calendarIcon' src={calendarIcon} alt="calendar" />
                                    <p>{currentPeriod}</p>
                                  </div>
                                </div>
                                {paymentSummary != null && paymentSummary.sort((a,b)=> a.id.split("0")[a.id.split("0").length-1]-0 - b.id.split("0")[b.id.split("0").length-1]-0).map(el=>{
                                  return(<div className='payments-wrap'>
                                  <h2 className='payments-wrap-title'>Бокс {el.id.split("0")[el.id.split("0").length-1]}</h2>
                                  <div className='payments-box'>
                                    {/* <img src="" alt="" /> */}
                                    <p className='payments-box-type'>Монеты:</p>
                                    <p className='payments-box-value'><span>{el.sumCoin} </span>₸</p>
                                  </div>
                                  <div className='payments-box'>
                                    {/* <img src="" alt="" /> */}
                                    <p className='payments-box-type'>Купюры:</p>
                                    <p className='payments-box-value'><span>{el.sumCash} </span>₸</p>
                                  </div>
                                  {currentPeriod !== "С последней инкассации" && <div className='payments-box'>
                                    {/* <img src="" alt="" /> */}
                                    <p className='payments-box-type'>QR: </p>
                                    <p className='payments-box-value'><span>{el.sumQR} </span>₸</p>
                                  </div>}
                                  
                                  <div className='payments-box'>
                                    {/* <img src="" alt="" /> */}
                                    <p className='payments-box-type'>Итого: </p>
                                    <p className='payments-box-value'><span>{el.sumCoin + el.sumCash + el.sumQR} </span>₸</p>
                                  </div>
                                </div>)
                                })}
                                <div onClick={exit} className='daterangeSelect-el daterangeSelect-close closeButton'>Выйти из кабинета</div>
                          </div>

  const items = [
    {
      key: '1',
      label: 'Статистика',
      children: statisticSection,
    },
    {
      key: '2',
      label: 'Инкассация',
      children: incasationSection,
    }
  ];

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  const getTotalSum = ()=>{

    const fetchData = async () => {
      try {
        const response = await axios.get("https://mwa.arc.kz/CarFash/GetTotalCashCollection?bin=" + user);
        setPaymentSummary(processRecords(response.data));
        console.log(paymentSummary)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
     
    };
    fetchData();
  }

  return (
    <div>
      <div className='sumBox today'>
        <p style={{margin: 0, padding: 0, fontWeight: 'bold', fontSize: '17px '}}>{currentPeriod}:</p>
        <p  className='sumBox-todaySum'>Монеты: <span>{paymentSummary && paymentSummary.reduce(function(accumulator, currentValue) {
                                                                                return accumulator + currentValue.sumCoin;
                                                                              }, 0)} ₸</span></p>
        <p  className='sumBox-todaySum'>Купюры: <span>{paymentSummary && paymentSummary.reduce(function(accumulator, currentValue) {
                                                                                return accumulator + currentValue.sumCash;
                                                                              }, 0)} ₸</span></p>
        {currentPeriod !== 'С последней инкассации' && <p  className='sumBox-todaySum'>QR: <span>{paymentSummary && paymentSummary.reduce(function(accumulator, currentValue) {
                                                                                return accumulator + currentValue.sumQR;
                                                                              }, 0)} ₸</span></p>}
        <p  className='sumBox-todaySum'>Итого: <span>{paymentSummary && paymentSummary.reduce(function(accumulator, currentValue) {
                                                                                return accumulator + currentValue.sumQR + currentValue.sumCash + currentValue.sumCoin;
                                                                              }, 0)} ₸</span></p>
      </div>  
      <Tabs defaultActiveKey="1" items={items} centered/>
  
      <div className='daterangeSelect-wrap'>
        <div className='incasationMenu' id='incasationMenu'>
          <div>
            <select className='inncasationSelect' name="" id="" onChange={(e)=>{setToIncasationData({...toIncasationData, station: e.target.value})}}>
              <option value={null}>Выберите бокс</option>
              {stationsForIncasations.map(el=>
                  <option value={el}>{el}</option>
                )}
              
           </select>
          </div>
          <div>
            <select className='inncasationSelect' name="" id="" onChange={(e)=>{setToIncasationData({...toIncasationData, paymentType: e.target.value})}}>
              <option value={null}>Выберите тип оплат</option>
              <option value={10}>Монеты</option>
              <option value={20}>Купюры</option>
              {/* <option value={30}>QR</option> */}
           </select>
          </div> 
          <div className='daterangeSelect-el daterangeSelect-close doIncassationButton' onClick={doIncassation}>
              <p>Провести инкассацию</p>
          </div>
          <div className='daterangeSelect-el daterangeSelect-close' onClick={()=>{showIncasationMenu(false)}}>
              <p>Закрыть</p>
          </div>
        </div>
        <div className='daterangeSelect calendar'>
            <p className='calendarTitle'>С даты: </p>
            <input className='calendarInput' type="date" onBlur={(e)=>{setDateInput({...dateInput, from: e.target.value}); console.log(dateInput)}}/>
            <p className='calendarTitle'>По дату: </p>
            <input className='calendarInput' type="date" onBlur={(e)=>{showDateCalendar(false); showDateSelect(false); setDateInput({...dateInput, to: e.target.value}); console.log(dateInput)}}/>
            <div className='daterangeSelect-el daterangeSelect-close' onClick={()=>{showDateCalendar(false)}}>
              <p>Закрыть</p>
            </div>
        </div>
        <div className='daterangeSelect'>
              <div className='daterangeSelect-el' onClick={()=>{getTotalSum(); showDateSelect(false); setCurrentPeriod('С последней инкассации')}}>
                <p>С последней инкассации</p>
              </div>
              <div className='daterangeSelect-el' onClick={()=>{setDateRange({from: formatDate(new Date()), to: formatDate(new Date())}); showDateSelect(false); setCurrentPeriod('За сегодня')} }>
                <p>За сегодня</p>
              </div>
              <div className='daterangeSelect-el' onClick={() => {
    const monday = getMonday(new Date());
    const endOfWeek = new Date(monday); // Создаем новый объект Date, чтобы не изменять оригинальный
    endOfWeek.setDate(monday.getDate() + 6);

    setDateRange({
        from: formatDate(monday),
        to: formatDate(endOfWeek)
    });showDateSelect(false);setCurrentPeriod('За неделю')
}} >
                <p>За неделю</p>
              </div>
              <div className='daterangeSelect-el' onClick={() => {
    const currentDate = new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    setDateRange({
        from: formatDate(startOfMonth),
        to: formatDate(endOfMonth)
    });
    showDateSelect(false); setCurrentPeriod('За месяц')
}}>
                <p>За Месяц</p>
              </div>
              <div className='daterangeSelect-el' onClick={() => {
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1); // 1 января текущего года
    const endOfYear = new Date(currentDate.getFullYear(), 11, 31); // 31 декабря текущего года

    setDateRange({
        from: formatDate(startOfYear),
        to: formatDate(endOfYear)
    });
    showDateSelect(false);
    setCurrentPeriod('За год');
}}>
                <p>За Год</p>
              </div>
              <div className='daterangeSelect-el' onClick={showDateCalendar}>
                <p>За Период</p>
              </div>
              <div className='daterangeSelect-el daterangeSelect-close' onClick={()=>{showDateSelect(false)}}>
                <p>Закрыть</p>
              </div>
            </div>
        </div>
    </div>
  );
};

export default PaymentStats;
